@use "@angular/material" as mat;
@import "styles-variables.scss";
@import "./app/shared/mat-table-responsive/mat-table-responsive.directive";
@import "./app/shared/auto-complete-field/auto-complete-field.component";
/* Font Family */
@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/material-icons/MaterialIcons-Regular.woff2")
            format("woff2"),
        url("/assets/fonts/material-icons/MaterialIcons-Regular.woff")
            format("woff"),
        url("/assets/fonts/material-icons/MaterialIcons-Regular.ttf")
            format("truetype");
}

.material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Default icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: "liga";
}
/* MDC Overrides */

.mdc-dialog__title::before {
    height: 0 !important;
}

@mixin spo-header1 {
    text-align: center;
    width: 100%;
    font-weight: 400;
    font-size: 2em;
    margin: 0;
    word-wrap: break-word;
    margin: auto;
    width: fit-content;
    color: $primary-color;
}
@mixin spo-header2 {
    margin-top: 20px;
    text-align: center;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    color: $accent-color;
}
@mixin spo-header3 {
    margin-top: 20px;
    text-align: center;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    color: $warn-color;
}
@mixin spo-center {
    text-align: center;
    width: 100%;
}

html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}
body {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
    height: 100%;
    color: $font;
    line-height: 1.1;
    word-wrap: break-word;
    text-rendering: geometricPrecision;
    box-sizing: border-box;
    @include big-screen {
        font-size: 14px;
    }
    @include small-screen {
        font-size: 16px;
    }
}

h1 {
    text-align: center;
    padding-bottom: 10px;
    width: 100%;
    font-weight: 400;
    font-size: 2em;
    margin: 0;
    word-wrap: break-word;
    margin: auto;
    width: fit-content;
    color: $primary-color;
}
h2 {
    font-weight: 400;
    padding-bottom: 8px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    width: 100%;
    font-size: 20px;
    color: $accent-color;
}

h3 {
    font-weight: 400;
    margin-top: 0px;
    text-align: center;
    width: 100%;
    font-size: 20px;
    color: $warn-color;
}
.ess-faq-question {
    font-size: medium;
    font-weight: bold;
    color: $primary-color;
}
.ess-faq-answer {
    color: $primary-color;
}
.whoo-markdown {
    h1 {
        font-weight: 400;
        text-align: left;
    }
    h2 {
        text-align: left;
        margin-top: 100px;
    }
    h3 {
        text-align: left;
    }
    p {
        text-align: left;
        margin-top: 0px;
        line-height: 1.5em;
    }
    img {
        max-width: 800px;
        border: 1px solid $subtitle-grey;
        border-radius: 4px;
        padding: 5px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        margin-bottom: 40px;
        //  width: 80%;
    }
    a {
        font-weight: bold;
        text-decoration: underline;
    }
}
p {
    margin: 0px;
    text-align: justify;
    line-height: 1.5em;
    margin-top: 32px;
    margin-bottom: 32px;
}
.whoo-poll-description-markdown {
    p {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

a {
    color: $accent-color;
    cursor: pointer;
    text-decoration: none;
}
.spo-center {
    @include spo-center;
}

.spo-container {
    .spo-container-title {
        @include spo-header1;
    }
    .spo-container-subtitle {
        @include spo-header2;
    }
    .spo-container-content {
        margin-top: 50px;
        margin-bottom: 50px;
        width: auto;
    }
}
.spo-container {
    .spo-container-content {
        @include big-screen {
            margin-left: 100px;
            margin-right: 100px;
        }
    }
}
@include small-screen {
    .spo-container {
        .spo-container-content {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
}
.article {
    margin-top: 50px;
    margin-bottom: 50px;
}
@include big-screen {
    .spo-field-container {
        margin: auto;
        margin-top: 5px;
        width: fit-content;
    }
    .spo-field-container > * {
        max-width: $big-screen-field-width;
        width: 100%;
        padding-bottom: 0px;
        width: $big-screen-field-width;
    }

    .spo-select-container {
        margin-top: 0px;
        width: fit-content;
    }

    .spo-select-container > * {
        max-width: 400px;
        width: 400%;
        padding-bottom: 0px;
    }

    .spo-select-short-container {
        margin-top: 0px;
        width: fit-content;
    }

    .spo-select-short-container > * {
        max-width: 100px;
        width: 100%;
        padding-bottom: 0px;
        width: 100px;
    }
}

@include small-screen {
    .spo-field-container {
        text-align: center;
        margin: auto;
        margin-top: 5px;
        width: fit-content;
    }
    .spo-field-container > * {
        width: 200px;
    }
}
.spo-border {
    border-color: $turkis-light;
    border-width: 1px;
    border-radius: 6px;
    border-style: solid;
}

.whoo-bdc-walk-popup {
    .header {
        margin-right: 24px;
    }
    .title .header {
        color: $accent-color !important;
    }
    button {
        color: $primary-color !important;
    }

    @include small-screen {
        min-width: 200px !important;
    }
}
.whoo-bdc-walk-popup-mobile-right {
    @include small-screen {
        .title {
            justify-content: flex-end !important;
        }
    }
}

//adjust cookie law style
.new-feature {
    .cookie-law-wrapper {
        background-color: $turkis !important;
        color: $white !important;
    }
}

//whoo stuff
.whoo-form {
    min-width: 150px;
    max-width: 800px;
    width: 100%;
}
.whoo-full-width {
    width: 100%;
}
.whoo-half-width {
    width: 50%;
}

.whoo-centered-container-fit-content {
    text-align: center;
    margin: auto;
    width: fit-content;
}

.whoo-centered-container {
    margin: auto;
}

.whoo-centered-container-70 {
    margin: auto;
    margin-top: 40px;
}

.whoo-centered-container-bigger {
    margin: auto;
}
.whoo-margin-top {
    @include big-screen {
        margin-top: 40px !important;
    }
}
@include small-screen {
    .whoo-margin-top {
        margin-top: 20px !important;
    }
}
@include big-screen {
    .whoo-centered-container-70 {
        min-width: $page-small-max-width;
        width: 70%;
    }
    .whoo-width-50 {
        width: 50%;
    }
    .whoo-centered-container {
        min-width: $page-small-max-width;
        width: 60%;
    }
    .whoo-centered-container-bigger {
        min-width: $page-small-max-width;
        width: 80%;
    }
}
.whoo-spinner-container {
    text-align: center;
    margin: auto;
    width: fit-content;
    .whoo-spinner {
        margin-top: 30px;
    }
}
@include small-screen {
    .whoo-hide-on-small-screen {
        display: none;
    }
}

@include big-screen {
    .whoo-long-button {
        min-width: 400px;
    }
}
@include small-screen {
    .whoo-long-button {
        min-width: 200px;
    }
}

.whoo-vote-box {
    @include whoo-background-box-white;
    padding: 20px;
    @include small-screen {
        padding-left: 4px;
        padding-right: 4px;
    }
    @include big-screen {
    }
}
.whoo-vote-box {
    transition: all 0.4s ease;
}
.edit-mode .whoo-vote-box {
    @include mat.elevation(6);
}
.allow-to-edit.read-mode .whoo-vote-box {
    &:hover {
        @include mat.elevation(5);
        background: $color-background-hover;
    }
}
.whoo-vote-box-title {
    color: $primary-color;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
}

.whoo-vote-box-row {
    display: flex;
    align-items: flex-start;
    padding-left: 12px;
    @include big-screen {
        padding-right: 16px;
    }
    @include small-screen {
        padding-right: 8px;
    }
}
.indent-enabled.outdent {
    .whoo-vote-box-row {
        @include big-screen {
            padding-left: 60px;
        }
        @include small-screen {
            padding-left: 16px;
        }
    }
    .whoo-vote-box-row.primary-answer-row {
        padding-left: 12px;
    }
}

.whoo-vote-box-field-row {
    @include small-screen {
        padding-left: 12px;
    }
    @include big-screen {
        padding-left: 12px;
    }
}
.whoo-responses {
    color: $subtitle-grey;
    font-size: 12px;
}

.whoo-background-box {
    @include whoo-background-box-white;
    padding: 20px;
    margin-bottom: 30px;
    margin: 12px;
    min-width: 300px;
}

.whoo-elevation-XXL {
    @include mat.elevation(24);
}
.whoo-elevation-XL {
    @include mat.elevation(20);
}
.whoo-elevation-L {
    @include mat.elevation(15);
}
.whoo-elevation-M {
    @include mat.elevation(10);
}
.whoo-elevation-S {
    @include mat.elevation(5);
}

.whoo-card-title {
    color: $primary-color;
    margin-top: 0px;
    margin-bottom: 0px;
    align-items: center;
}
.whoo-card-sub-title {
    color: $subtitle-light;
    padding-left: 0px;
}

.whoo-left-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.whoo-right {
    display: flex;
}

// COLUMNS
// Text based cells: grow, truncate and show ellipsis when there is not enough space
.whoo-flex-column {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 100%;
    width: inherit;
}

.whoo-option-card {
    margin-bottom: 20px;
}

.whoo-mat-icon-1 {
    transform: scale(1);
}

.icon-display {
    transform: scale(0.8);
}

.whoo-nav-bar-margin {
    @include nav-bar-margin;
}
.whoo-nav-bar-padding {
    @include nav-bar-padding;
}
.whoo-page-container {
    margin-right: auto;
    margin-left: auto;
    max-width: $page-max-width;
}
@include big-screen {
    .whoo-page-container {
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 150px;
    }
}
@include small-screen {
    .whoo-page-container {
        padding-left: 2px;
        padding-right: 2px;
        padding-bottom: 50px;
    }
}

.whoo-stepper-container {
    margin-right: auto;
    margin-left: auto;
    max-width: $page-big-max-width;
}
@include big-screen {
    .whoo-stepper-container {
        padding-left: 8px;
        padding-right: 8px;
    }
}
@include small-screen {
    .whoo-stepper-container {
        padding-left: 2px;
        padding-right: 2px;
        padding-bottom: 16px;
    }
}

.whoo-section-container {
    margin-right: auto;
    margin-left: auto;
    max-width: $page-max-width;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
}
@include small-screen {
    .whoo-section-container {
        padding-top: 5px;
        padding-left: 10px;
        padding-right: 10px;
    }
}
//*********************************

.whoo-editable-row-read-mode {
    // cursor: pointer;
    transition: all 0.4s ease;
    margin-top: 5px;
    margin-bottom: 2px;
    @include big-screen {
        border-radius: 25px;
    }
    @include small-screen {
        border-radius: 2px;
    }
}
.whoo-editable-row-read-mode:hover {
    background: $color-background-hover;
    box-shadow: 0 6px 10px 0 rgb(0 0 0 / 3.5%), 0 1px 18px 0 rgb(0 0 0 / 3%),
        0 3px 5px -1px rgb(0 0 0 / 5%);
}
.whoo-editable-row-edit-mode {
    transition: all 0.4s ease;
    margin-top: 5px;
    margin-bottom: 2px;
    border-radius: 25px;
    box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%),
        0 3px 5px -1px rgb(0 0 0 / 20%);
    @include big-screen {
        border-radius: 25px;
    }
    @include small-screen {
        border-radius: 2px;
    }
}

.whoo-align-container {
    display: flex;
    align-items: baseline;
}

.whoo-edit-inline-read-mode {
    position: relative;
    left: 4px;
    font-size: 14px;
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
}

.whoo-action-link {
    cursor: pointer;
    font-size: 12px;
    width: 100%;
    color: $warn-color;
    text-decoration: underline;
}
.whoo-action-link:hover {
    background: $color-background-hover;
}

.whoo-calendar-event-day {
    background: $warn-color-light !important;
    border-radius: 999px !important;
}

::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $color-background-lighter;
    box-shadow: inset 0 0 5px $color-background-lighter;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $warn-color-light;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $warn-color;
}

.whoo-why-whocan-container {
    position: relative;
    z-index: 2;
    @include big-screen {
        margin-top: 200px;
    }
    @include small-screen {
        margin-top: 30px;
    }
}

.whoo-whocan-advantages-container {
    position: relative;
    z-index: 2;
    @include big-screen {
        margin-top: 250px;
    }
    @include small-screen {
        margin-top: 30px;
    }
}

.whoo-how-it-works-section-container {
    position: relative;
    z-index: 2;
    @include big-screen {
        margin-top: 100px;
    }
    @include small-screen {
        margin-top: 80px;
    }
}

.whoo-event-examples-section-container {
    position: relative;
    z-index: 2;
    @include big-screen {
        margin-top: 150px;
    }
    @include small-screen {
        margin-top: 80px;
    }
}

.whoo-poll-templates-container {
    position: relative;
    z-index: 2;
    margin-right: 0px;
    margin-left: 0px;
    max-width: none;
}

.whoo-poll-templates {
    margin-top: 100px;
    margin-bottom: 50px;
    margin-right: auto;
    margin-left: auto;
    max-width: $page-max-width;
    position: relative;
    h2 {
        text-align: center;
        color: $primary-color;
        font-weight: bold;
        font-size: 2em;
    }
    @include debug-border;
    @include big-screen {
        margin-bottom: 200px;
    }
}
.whoo-testimonial-standalone-container {
    position: relative;
    z-index: 2;
    margin-top: 50px;
}
.whoo-founder-statement-container {
    position: relative;
    z-index: 2;
    @include small-screen {
        margin-top: 100px;
    }

    @include big-screen {
        margin-top: 50px;
    }
}
.whoo-testimonials {
    position: relative;
    z-index: 2;
    @include small-screen {
        margin-top: 100px;
    }
    h2 {
        text-align: left;
        color: $primary-color;
        font-weight: bold;
        font-size: 2em;
        @include small-screen {
            padding-left: 40px;
            padding-right: 40px;
            text-align: center;
        }
    }
}

.whoo-old-testimonials {
    margin-top: 100px;
    h2 {
        text-align: left;
    }
    .divider {
        margin-top: 60px;
        margin-bottom: 20px;
    }
}
.whoo-vote-preview {
    .mat-drawer-container {
        min-height: calc(100vh - $context-bar-height);
    }
}

.whoo-icon {
    color: $color-icon;
}

/********************************
Material customizations

RULES
- Custom css normally on the host element by using custom class
- Avoid inner style changes
- Inner changes - if needed -  all in style.scss
    - in ONE place
    - always by specifying the hosts class
    - use !important then to override the specifty
******************************************/

/**
    MAT-CARD
*/

@include big-screen {
    .mat-mdc-card {
        padding: 24px !important;
        margin: 12px;
    }
}
@include small-screen {
    .mat-mdc-card {
        padding: 12px !important;
        min-height: 30px !important;
        margin: 12px;
    }
}
.whoo-mat-icon-container {
    transform: scale(0.8);
    transform: translateY(4px);
}

/**
    DIALOG
*/

.small-screen-modal-full {
    align-self: flex-end !important;
}

.small-screen-modal-full .mat-mdc-dialog-container .mdc-dialog__surface {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.mat-mdc-dialog-container {
    h2 {
        font-weight: 800;
        color: $primary-color;
    }
}

.mat-mdc-dialog-content {
    @include small-screen {
        height: 65vh;
    }
}

/**
      STEPPER
*/

.mat-step-header {
    .mat-step-icon {
        background-color: $color-background-light !important;
    }

    .mat-step-icon-selected {
        background-color: $primary-color !important;
    }

    .mat-step-label {
        color: $color-background-light !important;
    }

    .mat-step-label.mat-step-label-selected {
        color: $primary-color !important;
    }
}

.mat-horizontal-stepper-header-container {
    padding-top: 24px;
    padding-right: 48px;
    padding-left: 48px;
}

.mat-vertical-content {
    @include small-screen {
        padding: 0 4px 4px 4px !important;
    }
}
/**
    RADIO BUTTON
*/
.whoo-vote {
    // vote component
    .mat-mdc-radio-label-content {
        margin-left: 6px;
    }
}

@include small-screen {
    .mat-mdc-radio-button {
        display: flex !important;
        flex-direction: column !important;
    }
}
.mat-mdc-radio-group {
    padding-top: 0px !important;
}
.mat-mdc-radio-button {
    margin-right: 30px !important;
}

.mat-mdc-radio-group {
    display: flex !important;
    flex-direction: column !important;
    @include big-screen {
        margin-left: 30px !important;
    }
    @include small-screen {
        margin-left: 0px !important;
    }

    .mat-mdc-radio-button {
        margin-left: 0px !important;
    }
}

/**
    MAT-SELECT
*/

.whoo-inline-select-container.whoo-edit-mode-true {
    height: 100%;
    background-color: $color-mat-form-field;
}
.whoo-inline-select-container.whoo-edit-mode-true:hover {
    @include mat.elevation(2);
}
.whoo-inline-select-container.appearance-matrix {
    font-size: 14px;
    height: 40px;
    width: 80px;
}
.whoo-inline-select-container {
    cursor: pointer;
    .mat-mdc-select {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: inherit !important;
        text-align: center !important;
        color: inherit !important; // to have the normal color even in disabled mode
        .mat-mdc-select-trigger {
            height: 100%;
        }
    }

    .mat-mdc-select-value {
        width: 100%;
    }
    .mat-mdc-select-arrow {
        display: none;
    }
    .mat-mdc-select-disabled {
        cursor: pointer !important;
    }
    .mat-mdc-select-trigger {
        cursor: pointer !important;
    }
}

.whoo-inline-select-option.mat-mdc-option.appearance-matrix {
    font-size: 14px;
}
.whoo-inline-select-option.mat-mdc-option.appearance-option-scrollable {
    font-size: 14px;
}

.whoo-inline-select-container.whoo-allday {
    .mat-mdc-select {
        position: relative;
        bottom: -13px;
        padding-bottom: 20px;
    }
}

.whoo-expandable-overlay-panel {
    // for select: to make the width of  overlay panel to
    // https://github.com/angular/components/issues/26336#issuecomment-1503201868
    width: auto !important;
}

/**
    MAT-FIELD
*/
/**
reduce the padding for form field on small screens to get more space
*/

.mdc-text-field {
    @include small-screen {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
}
/**
Hide the ripple line on mat-form-field with apearance fill. Relevant in particuclar for the inline fields.
But we reomve it for all to make it more homogen
*/
.mat-form-field-appearance-fill {
    .mdc-line-ripple {
        display: none;
    }
}
// Still display the ripple line if the vaule of the field is invalid
.mat-form-field-appearance-fill.mat-form-field-invalid {
    .mdc-line-ripple {
        display: block;
    }
}
/**
inline form fields
*/
.whoo-inline-form-field.mat-mdc-form-field {
    font-size: 14px;
}
.whoo-inline-form-field.whoo-input-text-align-right {
    input {
        text-align: center;
    }
}
.whoo-inline-form-field {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .mdc-text-field {
        padding-left: 6px;
        padding-right: 6px;
    }
    .mat-mdc-form-field-infix {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        min-height: fit-content !important;
    }
}

/**
auto complete
*/
.mat-mdc-autocomplete-panel {
    .mat-mdc-option {
        font-size: 14px !important; //  for result matrix - edit flag but it is a global change
    }
}
/**
    MAT-BUTTON
*/
.mat-mdc-menu-item {
    color: $color-icon !important;
    .mat-icon-no-color {
        color: $color-icon !important;
    }
}
.mdc-button {
    .mat-icon-no-color {
        color: $color-icon !important;
    }
}
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
    border-radius: 3px 30px;
    font-size: 16px;
    padding-right: 24px;
    padding-left: 30px;
}

.whoo-create-poll-button.mat-mdc-raised-button.mat-mdc-button-base {
    box-shadow: 2px 4px 36px rgba(2, 92, 255, 0.34);
}

.mat-mdc-raised-button.mat-warn {
    color: $primary-color !important;
}
.mat-mdc-raised-button.mat-warn {
    .mat-icon {
        color: $primary-color !important;
    }
}
.whoo-mini-fab.mat-mini-fab.mat-primary {
    color: $black;
    background-color: $color-mat-form-field;
}

.whoo-invitation-tab-group {
    .mat-mdc-tab-header {
        background-color: $color-mat-app-background;
        position: sticky;
        z-index: 100;
        top: 0;
        transition: top 1s; /* Transition effect when sliding down (and up) */
    }
}
